import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const ScrollToTopButtonDiv = styled.div`
  background: ${({ scrollNav }) =>
    scrollNav ? 'rgb(105,105,105,0.5)' : 'transparent'};
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 10;
  transition: 0.3s all ease;
  border-radius: 1rem;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
    right: 1rem;
    bottom: 1rem;
  }
`;

export const ScrollToTopButtonLogo = styled(LinkR)`
  color: ${({ scrollNav }) =>
    scrollNav ? '#fff' : 'transparent'};
  cursor: pointer;
  font-size: 1.5rem;
  margin: 0 auto;
`;
