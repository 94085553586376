import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Home from './pages';
import DontUseIE from './components/DontUseIE';

function App() {
  const userAgent = window.navigator.userAgent;
  const isIE = userAgent.indexOf('Trident/7.0;');

  return (
    <>
      {isIE === -1 ? (
        <Router>
          <Switch>
            <Route path="/" component={Home} exact />
          </Switch>
        </Router>
      ) : (
        <DontUseIE />
      )}
    </>
  );
}

export default App;
