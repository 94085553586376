import React from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  LogoIcon,
} from './SidebarElements';
import LogoIcon1 from '../../images/hayan_1.png';

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink
            to="about"
            onClick={toggle}
            offset={-80}
          >
            About
          </SidebarLink>
          <SidebarLink
            to="ceoGreeting"
            onClick={toggle}
            offset={-80}
          >
            CEO Greeting
          </SidebarLink>
          <SidebarLink
            to="services"
            onClick={toggle}
            offset={-80}
          >
            Services
          </SidebarLink>
          <SidebarLink
            to="contact"
            onClick={toggle}
            offset={-80}
          >
            Contact
          </SidebarLink>
          <LogoIcon src={LogoIcon1} />
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
