import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import ScrollToTopButton from '../components/ScrollToTopButton';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import InfoSectionCeo from '../components/InfoSectionCeo';
import InfoSectionContact from '../components/InfoSectionContact';
import { homeObjOne } from '../components/InfoSection/Data';
import { homeObjTwo } from '../components/InfoSectionCeo/Data';
import { homeObjThree } from '../components/InfoSectionContact/Data';
import Services from '../components/Services';
import Footer from '../components/Footer';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <ScrollToTopButton />
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <InfoSectionCeo {...homeObjTwo} />
      <Services />
      <InfoSectionContact {...homeObjThree} />
      <Footer />
    </>
  );
};

export default Home;
