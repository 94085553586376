export const homeObjTwo = {
  id: 'ceoGreeting',
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: 'CEO 인사말',
  headline: '대표 경영지도사 최두영',
  description:
    '반갑습니다. 변화하는 환경과 급변하는 트렌드에 적응하기 쉽지 않습니다. 그러나 "인사가 만사"라는 부분은 변화하지 않는 경영철학이라고 생각됩니다.',
  description2:
    '최근 정부는 일자리 관련 다양한 정책들을 발표하고 최저임금을 인상하면서 근로자의 삶의 질을 높여 국가경쟁력을 높이고 기업에게 적절한 지원을 통해서 성장할 수 있는 원동력을 제공하고 있습니다.',
  description3:
    '저희 하얀노무컨설팅은 기업의 대표님은 영업과 매출, 기술력에 집중하고 근로자는 법적인 보호와 안정 속에서 최선의 노력을 다하도록 도와드리겠습니다.',
  description4:
    '기업은 당당하게 사업하시고 근로자가 노동의 가치를 존중받는다면 기업에서 인사노무는 어려운 문제, 골치아픈 숙제가 아니라 기업 발전의 초석이고 근로자에게 든든한 울타리가 될 것입니다.',
  description5:
    '저희는 변화하는 정책과 법, 제도에 맞게 준비하고 공부하겠습니다. 항상 고객이 편하게 말씀하고 요청할 수 있도록 준비하는 파트너가 되겠습니다.',
  description6:
    '성장하는 여러분에게 작은 도움이 된다면 더할 나위 없이 기쁜',
  description7: '최두영 드림',
  buttonLabel: '컨설팅 서비스 소개',
  imgStart: false,
  img: require('../../images/choi_1.jpeg').default,
  alt: 'ceoGreeting',
  dark: true,
  primary: true,
  darkText: false,
};
