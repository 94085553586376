import React, { useState } from 'react';
import { Button } from '../ButtonElements';
import {
  DontUseIEContainer,
  DontUseIEBg,
  VideoBg,
  DontUseIEContent,
  DontUseIEH1,
  DontUseIEP,
  DontUseIEP2,
} from './DontUseIEElements';

const DontUseIE = () => {
  return (
    <DontUseIEContainer>
      <DontUseIEBg>
        <VideoBg
          playsInline
          autoPlay
          loop
          muted
          src="https://firebasestorage.googleapis.com/v0/b/sns-mkt-db.appspot.com/o/video-2mb.mp4?alt=media"
          type="video/mp4"
        />
      </DontUseIEBg>
      <DontUseIEContent>
        <DontUseIEH1>안녕하세요?</DontUseIEH1>
        <DontUseIEH1>하얀노무컨설팅 입니다.</DontUseIEH1>
        <DontUseIEP>Internet Explorer에서는</DontUseIEP>
        <DontUseIEP2>
          최적화된 화면을 지원하지 않습니다.
        </DontUseIEP2>
        <DontUseIEP>
          Chrome, Safari, Firefox, Edge 등의
        </DontUseIEP>
        <DontUseIEP2>
          최신 브라우저를 이용해 주세요.
        </DontUseIEP2>
        <DontUseIEP2>감사합니다.</DontUseIEP2>
      </DontUseIEContent>
    </DontUseIEContainer>
  );
};

export default DontUseIE;
