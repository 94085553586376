import React, { useState, useEffect } from 'react';
import { FaChevronUp } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import {
  ScrollToTopButtonDiv,
  ScrollToTopButtonLogo,
} from './ScrollToTopButtonElements';

const ScrollToTopButton = () => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 200) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <ScrollToTopButtonDiv scrollNav={scrollNav}>
        <ScrollToTopButtonLogo
          to="/"
          onClick={toggleHome}
          scrollNav={scrollNav}
        >
          <FaChevronUp />
        </ScrollToTopButtonLogo>
      </ScrollToTopButtonDiv>
    </>
  );
};

export default ScrollToTopButton;
