export const homeObjOne = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: '또 하나의 인사∙노무 지원팀',
  // 인사∙노무 아웃소싱 전문 컨설팅 그룹
  // 기업을 당당하게 하는 색깔을 찾아드립니다
  headline: 'Find the color of your company',
  description:
    // '저희는 대표님과 근로자들이 주요 업무에 매진하느라 찾지 못하시는 권리부터 시작하여, 안정적인 내부 운영을 위한 절세플랜 제공 및 인사가 만사라는 격언의 실현을 위한 인사노무제도 정비를 주요 슬로건으로 컨설팅하고 있습니다.',
    '저희는 대표님과 근로자들이 주요 업무에 매진하느라 찾지 못하시는 권리부터 시작하여, 안정적인 내부 운영을 위한 플랜 제공 및 인사가 만사라는 격언의 실현을 위한 인사∙노무제도 정비를 주요 슬로건으로 컨설팅하고 있습니다. 전문상담 컨설팅 회사로 중소기업 발전을 위해 늘 매진하겠습니다.',
  topLine2: '중소기업상담회사 등록',
  description2:
    '중소기업창업 지원법에 따라 신뢰할 수 있는 컨설팅 회사를 중소벤처기업부에 상담회사로 등록함으로써 중소기업에 보다 높은 컨설팅 서비스를 제공하게 되었습니다.',
  buttonLabel: 'CEO 인사말',
  imgStart: false,
  img: require('../../images/certified.png').default,
  alt: 'about',
  dark: true,
  primary: true,
  darkText: true,
};

export const homeObjThree = {
  id: 'contact',
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: 'Contact Us',
  headline: '고객님의 소중한 의견을 기다립니다.',
  description:
    'Phone : 010-3541-7964 / Email : hyolee20@hometax.go.kr',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/svg-2.svg').default,
  alt: 'contact',
  dark: false,
  primary: false,
  darkText: false,
};
