export const homeObjThree = {
  id: 'contact',
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: 'Contact Us',
  headline: '고객님의 소중한 의견을 기다립니다.',
  description:
    '전화나 이메일로 개괄적인 설명을 주시면, 담당자를 지정하여 연락 드립니다.',
  description2: 'Phone : 010-2535-8692',
  description3: 'Email : hyolee20@naver.com',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/contact.svg').default,
  alt: 'contact',
  dark: false,
  primary: false,
  darkText: false,
};
