import React from 'react';
import IconServices1 from '../../images/services-1.svg';
import IconServices2 from '../../images/services-2.svg';
import IconServices3 from '../../images/services-3.svg';
import IconServices4 from '../../images/services-4.svg';
import IconServices5 from '../../images/services-5.svg';
import IconServices6 from '../../images/services-6.svg';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesH2Red,
  ServicesP,
  BtnWrap,
} from './ServicesElements';
import { Button } from '../ButtonElements';

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={IconServices1} />
          <ServicesH2>기업 자문 및 인사 관리</ServicesH2>
          <ServicesP>
            기업의 인사제도 운영방법에 대한 지도 및 자문을
            해 드립니다.
            <p>
              변경되는 노동관계법령 및 정책에 부합하는
              인사관리를 책임집니다.
            </p>
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={IconServices2} />
          <ServicesH2>
            {/* 근로계약서 작성 및 체결 */}
            중소기업의 사업성 평가,
            <p>
              {/* 취업규칙 작성 및 신고 */}
              경영 및 기술 향상을 위한 용역
            </p>
          </ServicesH2>
          <ServicesP>
            {/* 근로자와 1:1 면담후, 기업별 근로형태 분석하여
            근로계약서 작성합니다. */}
            본 사업에 딸린 사업으로서 중소벤처기업부장관이
            정하는 사업
            {/* <p>
              상시근로자 수 10인 이상 사업장에 필수인
              취업규칙을 작성해드립니다.
            </p> */}
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={IconServices3} />
          <ServicesH2Red>
            중소기업 인력지원 특별법,
          </ServicesH2Red>
          <ServicesH2>
            {/* 임금체계 컨설팅 */}
            중소기업기본법, 중소기업진흥에 관한 법률,
            대ㆍ중소기업 상생협력촉진에 관한 법률,
            벤처기업육성에 관한 특별조치법 등에 따른 업무
            {/* <p>급여/4대보험 관리</p> */}
          </ServicesH2>
          {/* <ServicesP>
            임금체계 개선을 통해 급여대장을 재구성해
            드립니다.
            <p>
              비과세 등을 활용한 4대 보험 절감 컨설팅을
              제공합니다.
            </p>
          </ServicesP> */}
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={IconServices4} />
          <ServicesH2>
            {/* 고용노동부 지원금 사업 */}
            인적자원 협상ㆍ면담 및
            <p>갈등관리 및 대안 제시</p>
          </ServicesH2>
          <ServicesP>
            {/* 고용창출장려금, 고용안정장려금, 일자리 함께하기
            등, 기업의 인사제도 점검 및 정비 후 적합한
            지원금을 추천, 진행해 드립니다. */}
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={IconServices5} />
          <ServicesH2>
            {/* 임금/퇴직금 등 체불사건 */}
            인사, 조직, 노무,
            <p>사무관리의 진단ㆍ지도</p>
          </ServicesH2>
          {/* 지급받고 있는 급여 안에 모든 법정수당이 제대로
            포함되어 있는지 의문스러운 경우, 사업주가
            퇴직금을 미리 월급에 포함해서 지급했다고 하거나,
            퇴직금을 지급받지 않기로 서로 합의하였다고
            주장하는 경우, 그밖에 본인의 임금에 문제가
            있다고 생각되거나, 실제로 급여를 전액 지급받지
            못하고 있을때, 관련 노동사건에 대한 업무를
            대리하여 수행해 드립니다. */}
          <ServicesP>
            {/* 지급받고 있는 급여 안에 모든 법정수당이 제대로
            포함되어 있는지 혹은 임금에 문제는 없는지
            의문스러운 경우, 관련 노동사건에 대한 업무를
            대리하여 수행해 드립니다. */}
            중소기업 관계 법령에 따라 기관에 하는 신고,
            신청, 진술, 보고
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={IconServices6} />
          <ServicesH2>
            {/* 부당해고 구제신청 사건 */}
            유통ㆍ판매관리 및 수출입
            <p>업무의 진단ㆍ지도</p>
          </ServicesH2>
          <ServicesP>
            {/* 미리 해고의 예고를 받지 못하거나, 해고예고수당을
            지급 받지 못하고 해고된 경우, 그 밖에 부당한
            해고를 당했을 때, 관련 사건에 대한 업무를
            대리하여 수행해 드립니다. */}
            지속가능경영 컨설팅, 기업부설연구소 설립,
            사업계획서 작성 등
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
      <BtnWrap>
        <Button
          to="contact"
          smooth={true}
          duration={800}
          spy={true}
          exact="true"
          offset={-80}
          primary={1}
          dark={1}
        >
          Contact 정보
        </Button>
      </BtnWrap>
    </ServicesContainer>
  );
};

export default Services;
