import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  LogoIcon,
} from './FooterElements';
import LogoIcon1 from '../../images/hayan_1.png';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              ㈜하얀노무컨설팅
            </SocialLogo>
            <WebsiteRights>
              사업자번호 : 636-88-01863
              <br />
              강남구 테헤란로 151 역삼하이츠빌딩 17층
              <br />
              ㈜하얀노무컨설팅 © {new Date().getFullYear()}{' '}
              All right reserved.
            </WebsiteRights>
            <LogoIcon src={LogoIcon1} />
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
