import React, { useState } from 'react';
import { Button } from '../ButtonElements';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from './HeroElements';

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg
          playsInline
          autoPlay
          loop
          muted
          src="https://firebasestorage.googleapis.com/v0/b/sns-mkt-db.appspot.com/o/video-2mb.mp4?alt=media"
          type="video/mp4"
        />
      </HeroBg>
      <HeroContent>
        <HeroH1>인사∙노무</HeroH1>
        <HeroH1>아웃소싱 전문</HeroH1>
        <HeroH1>컨설팅 그룹</HeroH1>
        <HeroP>
          인사・노무는 전문가인 저희에게 맡기시고,
        </HeroP>
        <HeroP>
          대표님께서는 안심하고 본 업무에 집중하세요!!
        </HeroP>
        <HeroBtnWrapper>
          <Button
            to="about"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
            smooth={true}
            duration={800}
            spy={true}
            exact="true"
            offset={-80}
          >
            about ㈜하얀노무컨설팅{' '}
            {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
